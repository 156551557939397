<style lang="sass" scoped>

</style>
<template lang="pug">
#app
  router-view
  v-dialog

</template>

<script>
export default {
  components: {

  },
  mounted() {
    // this.$Progress.finish()
    // this.$store.dispatch('session')
  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  created () {

  },
}
</script>
