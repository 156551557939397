import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './bootstrap.sass'
import './main.sass'

import Vuex from 'vuex'
import Store from './store'
import $http from './http'

import VModal from 'vue-js-modal'
Vue.use(VModal, {
  dynamic: true,
  dialog: true
})

import numeral from 'numeral'
import moment from 'moment'
import isString from 'lodash/isString'
import VueShortcut from 'vue-shortkey'

moment.locale('ko')

import {
  BootstrapVue,
} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(Store.visibilityChange)
// Vue.use(VueShortcut, {
//   prevent: ['input', 'textarea'],
// })

import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin)

import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

Vue.use(BootstrapVueIcons)

import VueScrollStop from 'vue-scroll-stop'

Vue.use(VueScrollStop)

// import VueExcelEditor from 'vue-excel-editor'
// Vue.use(VueExcelEditor)

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

// import VueHotkey from 'v-hotkey'
// Vue.use(VueHotkey)

Vue.prototype.$store = Store.create()
Vue.prototype.$http = $http

Vue.config.productionTip = false


import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://c893454ebe224adc89578e8dee3451cd@o955740.ingest.sentry.io/5905099",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["app.noitaler.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.filter('datetime', value => {
  if (!value) return ''
  return moment(value).format('YYYY-MM-DD HH:mm')
})
Vue.filter('datetime_dow', value => {
  if (!value) return ''
  return moment(value).format('YYYY.MM.DD (ddd) HH:mm')
})
Vue.filter('fromNow', value => {
  if (!value) return ''
  return moment(value).fromNow()
})
// Vue.filter('format', (value, format) => {
//   if (!value) return ''
//   return moment(value).format(format)
// })
Vue.filter('date', (value, placeholder) => {
  if (!value) return (isString(placeholder) ? placeholder : '') || ''
  return moment(value).format('YYYY.MM.DD')
})
Vue.filter('localdate', (value, placeholder) => {
  if (!value) return (isString(placeholder) ? placeholder : '') || ''
  return moment(value).format('YYYY년 M월 D일 (ddd)')
})
Vue.filter('localdate_dateonly', (value, placeholder) => {
  if (!value) return (isString(placeholder) ? placeholder : '') || ''
  return moment(value).format('YYYY년 M월 D일')
})
Vue.filter('localdate_dowonly', (value, placeholder) => {
  if (!value) return (isString(placeholder) ? placeholder : '') || ''
  return moment(value).format('ddd')
})
Vue.filter('time', (value, placeholder) => {
  if (!value) return (isString(placeholder) ? placeholder : '') || ''
  return moment(moment().format('YYYY-MM-DD') + ' ' + value).format('A h:mm')
})
// Vue.filter('numeral', (value, format = '0,0') => {
//   return numeral(value).format(format)
// })
Vue.filter('number', (value) => {
  return numeral(value).format('0,0')
})
Vue.filter('encodeText', (t) => {
  t = String(t).replace(/\s|\//g, '-')
  return encodeURIComponent(t)
})
Vue.filter('encodeReadableText', (t) => {
  t = String(t).replace(/\s|\//g, '-')
  return t // encodeURIComponent(t)
})
// Vue.filter('number2', (value) => {
//   return numeral(value).format('0,0.00')
// })
// Vue.filter('percentage', (value) => {
//   return Number(+value).toFixed(0) + '%'
// })
// Vue.filter('percentage2', (value) => {
//   return Number(+value).toFixed(2) + '%'
// })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

console.table(process.env)
console.log(`%capp.noitaler.com`, 'font-family:monospace; background-color:#00a99c; color:#fff; padding:1rem;')
