import { render, staticRenderFns } from "./IndexFooter.vue?vue&type=template&id=addf7256&scoped=true&lang=pug&"
import script from "./IndexFooter.vue?vue&type=script&lang=js&"
export * from "./IndexFooter.vue?vue&type=script&lang=js&"
import style0 from "./IndexFooter.vue?vue&type=style&index=0&id=addf7256&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "addf7256",
  null
  
)

export default component.exports