<style lang="sass" scoped>
.link
  font-weight: 700
  &:hover
    text-decoration: none
.index-nav
  background-color: #2b2d42
  border-top: solid 1px lighten(#2b2d42, 5%);
  a
    color: #edf2f4 !important
    &:hover
      color: #fff !important

</style>
<template lang="pug">
<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 border-bottom shadow-sm index-nav">
  h5.my-0.mr-md-auto.font-weight-normal
    router-link.text-dark.link(to='/') Noitaler
  <nav class="my-2 my-md-0 mr-md-3">
    <a class="p-2 text-dark" href="#">Features</a>
    <a class="p-2 text-dark" href="#">Enterprise</a>
    <a class="p-2 text-dark" href="#">Support</a>
    <a class="p-2 text-dark" href="#">Pricing</a>
  </nav>
  router-link.btn.btn-outline-dark(to='/signup' v-show='!session.id') Sign up
  router-link.btn.btn-dark(to='/property' v-show='session.id') 나의 계정
  //- a.btn.btn-outline-primary(href='/signup') Sign up
</div>
</template>

<script>

export default {
  name: 'IndexNav',
  props: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  data() {
    return {

    }
  },
  async mounted() {

  },
  methods: {

  },
}
</script>
