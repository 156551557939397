<style lang="sass" scoped>
.header
  font-weight: bold
.item
  line-height: 2rem
</style>
<template lang="pug">
.border-top.mt-4
  .container
    .row.pt-4
      .col-lg.pb-4
        h5.header 연락처
        a.item.d-block(href='#') 이용문의
        span.item.d-block 연락처 +82 1544 1544
      .col-lg.pb-4
        h5.header 소개
        a.item.d-block(href='#') 요금제
        a.item.d-block(href='#') 주요기능
        a.item.d-block(href='#') 제휴
        a.item.d-block(href='#') 분야별 목표
      .col-lg.pb-4
        h5.header 리소스
        a.item.d-block(href='#') 블로그
        a.item.d-block(href='#') 고객 사례
        a.item.d-block(href='#') 파트너
        a.item.d-block(href='#') API
      .col-lg.pb-4
        h5.header 지원
        a.item.d-block(href='#') 도움말
        a.item.d-block(href='#') System Status
        a.item.d-block(href='#') 유투브
        a.item.d-block(href='#') 방문 일정
      .col-lg.pb-4
        h5.header Studio INTO 소개
        a.item.d-block(href='https://studiointo.asia') 팀
        a.item.d-block(href='https://studiointo.asia') 채용
        a.item.d-block(href='https://studiointo.asia') 보도자료
        a.item.d-block(href='https://studiointo.asia') 약관

</div>
</template>

<script>
export default {
  name: 'IndexNav',
  props: {

  },
  computed: {

  },
  data() {
    return {

    }
  },
  async mounted() {

  },
  methods: {

  },
}
</script>
