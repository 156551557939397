<style lang="sass" scoped>
.container-login
  font-size: 12px
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div.container-login
  div.container-left
    .container-left-top.rounded-left.shadow-sm.ml-1
  div.container-right.bg-white.shadow-sm
    .container
      .row.justify-content-center.align-items-center(style='height: 100vh')
        .col-xl-5.col-lg-6.col-md-7
          h3.title.pb-3 페이지를 찾을 수 없습니다.

</template>

<script>
import IndexNav from '../components/IndexNav'
import IndexFooter from '../components/IndexFooter'

export default {
  name: 'page404',
  components: {
    IndexNav,
    IndexFooter,
  },
  data() {
    return {
      done: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.done = true
    }, 300);
  }
}
</script>
