import Vuex from 'vuex'
import $http from './http'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'

const delay = ms => new Promise(_ => setTimeout(_, ms))

const store = {
  state: {
    session: {},
    property: {},
    message_templates: [],
    message_templates_by_status: {},
    status_config: [],
    status_config_by_text: {},
    status_flows: [],
    status_flows_counts_by_status: {},
    status_flows_counts_color_by_status: {},
    status_flows_counts_total: 0,
    tags: [],
    users: [],
    users_by_id: {},
    documents: [],
    documents_by_id: {},
    pipelines: [],
    pipelines_by_id: {},
    slack_config: {},
    slack_config_channels_by_id: {},
    email_config: {},
    kakao_config: {},
    user_permissions: [],
  },
  mutations: {
    session(state, session) {
      console.log('session loaded')
      state.session = session
    },
    message_templates(state, message_templates) {
      console.log('message_templates loaded')
      state.message_templates = message_templates
    },
    message_templates_by_status(state, message_templates_by_status) {
      console.log('message_templates_by_status loaded')
      state.message_templates_by_status = message_templates_by_status
    },
    status_config(state, status_config) {
      console.log('status_config loaded')
      state.status_config = status_config
    },
    status_config_by_text(state, status_config_by_text) {
      console.log('status_config_by_text loaded')
      state.status_config_by_text = status_config_by_text
    },
    status_flows(state, status_flows) {
      console.log('status_flows loaded')
      state.status_flows = status_flows
    },
    status_flows_counts_by_status(state, status_flows_counts_by_status) {
      console.log('status_flows_counts_by_status loaded')
      state.status_flows_counts_by_status = status_flows_counts_by_status
    },
    status_flows_counts_color_by_status(state, status_flows_counts_color_by_status) {
      console.log('status_flows_counts_color_by_status loaded')
      state.status_flows_counts_color_by_status = status_flows_counts_color_by_status
    },
    status_flows_counts_total(state, status_flows_counts_total) {
      console.log('status_flows_counts_total loaded')
      state.status_flows_counts_total = status_flows_counts_total
    },
    tags(state, tags) {
      console.log('tags loaded')
      state.tags = tags
    },
    property(state, property) {
      console.log('property loaded')
      state.property = property
    },
    users(state, users) {
      console.log('users loaded')
      state.users = users
    },
    users_by_id(state, users_by_id) {
      console.log('users_by_id loaded')
      state.users_by_id = users_by_id
    },
    documents(state, documents) {
      console.log('documents loaded')
      state.documents = documents
    },
    documents_by_id(state, documents_by_id) {
      console.log('documents_by_id loaded')
      state.documents_by_id = documents_by_id
    },
    pipelines(state, pipelines) {
      console.log('pipelines loaded')
      state.pipelines = pipelines
    },
    pipelines_by_id(state, pipelines_by_id) {
      console.log('pipelines_by_id loaded')
      state.pipelines_by_id = pipelines_by_id
    },
    slack_config(state, slack_config) {
      console.log('slack_config loaded')
      state.slack_config = slack_config
    },
    slack_config_channels_by_id(state, slack_config_channels_by_id) {
      console.log('slack_config_channels_by_id loaded')
      state.slack_config_channels_by_id = slack_config_channels_by_id
    },
    email_config(state, email_config) {
      console.log('email_config loaded')
      state.email_config = email_config
    },
    kakao_config(state, kakao_config) {
      console.log('kakao_config loaded')
      state.kakao_config = kakao_config
    },
    user_permissions(state, user_permissions) {
      console.log('user_permissions loaded')
      state.user_permissions = user_permissions
    },
  },
  actions: {
    'force validate session'(context) {
      context.state.session.checked = false
      context.dispatch('session')
    },

    'tags'(context, property_id) {
      context.dispatch('status flows', property_id)
    },
    'message templates'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/views/message-templates`)
          const message_templates = r.data.rows

          const message_templates_by_status = {}
          for(const e of message_templates) {
            if (!message_templates_by_status[e.target.status]) {
              message_templates_by_status[e.target.status] = []
            }
            message_templates_by_status[e.target.status].push(e)
          }
          console.log(5000, message_templates_by_status)

          context.commit('message_templates', message_templates)
          context.commit('message_templates_by_status', message_templates_by_status)
          return resolve(true)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },
    'status flows'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}`)
          const property = r.data.property
          const status_flows = property.status_flow_template.split(',')
          const tags = compact((property.customer_tags_template || '').split('\n'))

          let status_config = []
          const status_config_by_text = {}
          if(property.status_config) {
            status_config = JSON.parse(property.status_config)
          }
          for(const e of status_config) {
            status_config_by_text[e.text] = e
          }

          context.commit('status_flows', status_flows)
          context.commit('status_config', status_config)
          context.commit('status_config_by_text', status_config_by_text)
          context.commit('tags', tags)
          context.commit('property', property)
          return resolve(true)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },
    'status flows count'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/orders/count`)
          if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '가져오기 실패')

          const counts = r.data.counts
          const counts_by_status = {}
          for (const c of counts) {
            counts_by_status[c.status_text] = c.count
          }

          const counts_color = r.data.counts_color
          const counts_color_by_status = {}
          for (const c of counts_color) {
            counts_color_by_status[c.status_text] = c.count
          }
          const count_all = r.data.total_count

          context.commit('status_flows_counts_by_status', counts_by_status)
          context.commit('status_flows_counts_color_by_status', counts_color_by_status)
          context.commit('status_flows_counts_total', count_all)
          return resolve(true)
        } catch (error) {
          console.log('status flows count @error', error)
          reject(error)
        }
      })
    },
    'users by id'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/users`)
          if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '가져오기 실패')

          const users = r.data.users
          const users_by_id = {}
          for (const e of users) {
            users_by_id[e.id] = e
          }

          context.commit('users', users)
          context.commit('users_by_id', users_by_id)
          return resolve(true)
        } catch (error) {
          console.log('users by id @error', error)
          reject(error)
        }
      })
    },
    'documents'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/views/documents`, {

          })
          if (r?.data?.message != 'ok') throw new Error('고객목록 가져오기 실패')
          const rows = r.data.rows

          // const documents = sortBy(rows.map(e => {
          //   return e
          // }), 'config.order')
          const documents = sortBy(rows, 'config.order')
          const documents_by_id = {}
          for (const e of documents) {
            documents_by_id[e.id] = e
          }

          context.commit('documents', documents)
          context.commit('documents_by_id', documents_by_id)
          return resolve(true)
        } catch (error) {
          console.log('documents @error', error)
          reject(error)
        }
      })
    },
    'pipelines'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/views/pipelines`, {

          })
          if (r?.data?.message != 'ok') throw new Error('pipelines 가져오기 실패')
          const rows = r.data.rows

          const pipelines = sortBy(rows.map(e => {
            return e
          }), 'config.order')
          const pipelines_by_id = {}
          for (const e of pipelines) {
            pipelines_by_id[e.id] = e
          }

          context.commit('pipelines', pipelines)
          context.commit('pipelines_by_id', pipelines_by_id)
          return resolve(true)
        } catch (error) {
          console.log('pipelines @error', error)
          reject(error)
        }
      })
    },
    'property'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}`)
          const property = r.data.property

          context.commit('property', property)
          return resolve(true)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },
    'property v2'(context, property_subdomain) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v2/property/${property_subdomain}`)
          if (r.data.message != 'ok') throw r.data

          const property = r.data.property

          context.commit('property', property)
          return resolve(property)
        } catch (error) {
          console.log(error)
          error.action = 'REDIRECT'
          reject(error)
        }
      })
    },
    'user permissions v2'(context, property_subdomain) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v2/property/${property_subdomain}/user_permissions`)
          if (r.data.message != 'ok') throw r.data

          const rows = r.data.rows

          context.commit('user_permissions', rows)
          return resolve(rows)
        } catch (error) {
          console.log(error)
          // error.action = 'REDIRECT'
          reject(error)
        }
      })
    },
    'slack config'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/slack_config`)
          // not support multi org
          const slack_config = r.data?.rows && r.data?.rows[0] || null
          const slack_config_channels_by_id = {}
          if (slack_config?.channel_json) {
            for (const e of slack_config.channel_json) {
              slack_config_channels_by_id[e.id] = e
            }
          }

          context.commit('slack_config', slack_config)
          context.commit('slack_config_channels_by_id', slack_config_channels_by_id)
          return resolve(true)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },
    'email config'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/email_config`)
          const email_config = r.data

          context.commit('email_config', email_config)
          return resolve(true)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },
    'kakao config'(context, property_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await $http.get(`/v1/property/${property_id}/kakao/config`)
          const kakao_config = r.data

          context.commit('kakao_config', kakao_config)
          return resolve(true)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },

    async session(context) {
      return new Promise(async (resolve, reject) => {
        try {
          if (context.state.session.checked) return resolve(true)

          for (let i = 0; i < 3; i++) {
            try {
              $http.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.studio_token}`
              const r = await $http.get(process.env.VUE_APP_BASE_API_URL + '/v1/profile/me')
              if (r?.data?.message != 'ok') {
                if (String(r?.data?.error).includes('Name=signed')) {
                  return reject(new Error(r?.data?.error || '인증된 계정만 이용 가능합니다.'))
                }
                throw new Error('retry')
              }

              // refreshed token
              window.localStorage.studio_token = r.data.token

              const session = r.data.session

              session.checked = true
              session.loaded = true
              session.intercom_user_hash = r.data.intercom_user_hash
              window.amplitude?.getInstance().setUserId(session.id)
              if (process.env.VUE_APP_VERSION) {
                window.amplitude?.getInstance().setVersionName(process.env.VUE_APP_VERSION)
              }
              context.commit('session', session)
              return resolve(true)
            } catch (error) {
              if (i >= 2) {
                throw new Error('로그인이 필요합니다. (4000)')
              }
              await delay(300)
              continue
            }
          }
        } catch (error) {
          const session = {loaded: true}
          context.commit('session', session)

          console.log(error)
          reject(error)

          if (window.analytics) {
            window.analytics.reset()
          }
        }
      })
    },

    'clear session'(context) {
      window.localStorage.studio_token = ''
      $http.defaults.headers.common['Authorization'] = ''
      context.state.session = {
        checked: false
      }
      window.amplitude?.getInstance().setUserId(null)
      if (window.analytics) {
        window.analytics.reset()
      }
    },
  }
}

export default {
  create() {
    return new Vuex.Store(store)
  },
  visibilityChange: {
    install(Vue) {

      document.addEventListener('visibilitychange', () => {
        console.log('hidden', document.hidden)
        if (document.visibilityState === 'visible') {
          console.log('visible')
          // public
          if (location.pathname.includes('/start-from-scratch')) {
            // 항목관리 바꾸고 돌아오는 경우 새로고침
            if (Vue.prototype.$store && Vue.prototype.$store.state.documents && Vue.prototype.$store.state.documents[0]) {
              Vue.prototype.$store.dispatch('documents', Vue.prototype.$store.state.documents[0].property_id)
            }
          }
        }
      })
    },
  },
}
