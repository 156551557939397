import Vue from 'vue'
import VueRouter from 'vue-router'
import Page404 from './views/Page404.vue'
import PropertyPage404 from './views/PropertyPage404.vue'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      component: () => import('./views/Layout.vue'),
      children: [
        {
          path: '',
          name: 'index',
          component: () => import('./views/Index.vue'),
        },
        {
          path: 'signin/process',
          component: () => import('./views/SigninProcess.vue'),
        },
        {
          path: 'signup/connect',
          name: 'signup.connect',
          component: () => import('./views/SignupConnect.vue'),
        },
        {
          path: '/pub/Landing',
          component: () => import('./views/pub/Landing.vue'),
        },
        {
          path: '/pub/Sample0109',
          component: () => import('./views/pub/Sample0109.vue'),
        },
        {
          path: '/pub/LoginForm',
          component: () => import('./views/pub/LoginForm.vue'),
        },
        {
          path: '/pub/LogoutForm',
          component: () => import('./views/pub/LogoutForm.vue'),
        },
        {
          path: '/pub/PropertyList',
          component: () => import('./views/pub/PropertyList.vue'),
        },
        {
          path: '/pub/SignupForm',
          component: () => import('./views/pub/SignupForm.vue'),
        },
        {
          path: '/pub/PropertyLayout',
          component: () => import('./views/pub/PropertyLayout.vue'),
        },
        {
          path: '/pub/PropertyLayoutH',
          component: () => import('./views/pub/PropertyLayoutH.vue'),
        },
        {
          path: '/pub/PropertyCustomer',
          component: () => import('./views/pub/PropertyCustomer.vue'),
        },
        {
          path: '/pub/PropertyCustomerEmpty',
          component: () => import('./views/pub/PropertyCustomerEmpty.vue'),
        },
        {
          path: '/pub/PropertyCustomerEmptyNew',
          component: () => import('./views/pub/PropertyCustomerEmptyNew.vue'),
        },
        {
          path: '/pub/PropertyCustomerEmptyNew2',
          component: () => import('./views/pub/PropertyCustomerEmptyNew2.vue'),
        },
        {
          path: '/pub/PropertyCustomerNewScratch',
          component: () => import('./views/pub/PropertyCustomerNewScratch.vue'),
        },
        {
          path: '/pub/PropertyCustomerNewCSV',
          component: () => import('./views/pub/PropertyCustomerNewCSV.vue'),
        },
        {
          path: '/pub/PropertyCustomerNewCSVMatching',
          component: () => import('./views/pub/PropertyCustomerNewCSVMatching.vue'),
        },
        {
          path: '/pub/PropertyCustomerNewCSVCustomer',
          component: () => import('./views/pub/PropertyCustomerNewCSVCustomer.vue'),
        },
        {
          path: '/pub/PropertyCustomerNewScratchCustomer',
          component: () => import('./views/pub/PropertyCustomerNewScratchCustomer.vue'),
        },
        {
          path: '/pub/PropertyCustomerNewScratchAny',
          component: () => import('./views/pub/PropertyCustomerNewScratchAny.vue'),
        },
        {
          path: '/pub/NewScratchCustomer',
          component: () => import('./views/pub/NewScratchCustomer.vue'),
        },
        {
          path: '/pub/NewScratchAny',
          component: () => import('./views/pub/NewScratchAny.vue'),
        },
        {
          path: '/signup',
          component: () => import('./views/Signup.vue'),
        },
        {
          path: '/logout',
          component: () => import('./views/Logout.vue'),
        },
        {
          path: '/sample-0803',
          component: () => import('./views/Sample0803.vue'),
        },
        {
          path: '/sample-1229',
          component: () => import('./views/Sample1229.vue'),
        },
        {
          path: '/property/new',
          name: 'property.new',
          component: () => import('./views/PropertyNew.vue'),
          meta: {
            sessionRequired: true,
            scopeRequired: ['signed'],
          }
        },
        {
          path: '/property',
          component: () => import('./views/Property.vue'),
          meta: {
            sessionRequired: true,
            scopeRequired: ['signed'],
          }
        },
        {
          path: '/property/:property_id/customer/:document_id/export/result',
          props: true,
          component: () => import('./views/CustomerExportResult.vue'),
          meta: {
            sessionRequired: true,
            scopeRequired: ['signed'],
          }
        },
        {
          path: '/property/:property_id',
          props: true,
          component: () => import('./views/PropertyLayout.vue'),
          meta: {
            sessionRequired: true,
          },
          children: [
            {
              path: '/',
              redirect: 'activity',
            },
            // {
            //   path: 'order/:order',
            //   props: true,
            //   component: () => import('./views/CustomerViewDetail.vue'),
            //   children: [
            //     {
            //       path: '',
            //       props: true,

            //     }
            //   ]
            // },
            {
              path: 'views',
              props: true,
              component: () => import('./views/CustomerViewLayout.vue'),
              children: [
                {
                  path: 'page/:order_id',
                  props: true,
                  component: () => import('./views/CustomerViewDetail.vue'),
                },
                {
                  path: 'message/new',
                  props: true,
                  component: () => import('./views/CustomerViewMessage.vue'),
                },
                {
                  path: 'message/sent',
                  props: true,
                  component: () => import('./views/CustomerViewMessageList.vue'),
                },
                {
                  path: '',
                  props: true,
                  component: () => import('./views/CustomerView.vue'),
                  children: [
                    {
                      path: ':order_id',
                      props: true,
                      component: () => import('./views/CustomerViewDetail.vue'),
                    }
                  ]
                }
              ]
            },
            {
              path: 'activity',
              props: true,
              component: () => import('./views/Activity.vue'),
            },
            {
              path: 'customer',
              props: true,
              component: () => import('./views/CustomerList.vue'),
              children: [
                // {
                //   path: ':document_id/start-from-scratch',
                //   props: true,
                //   component: () => import('./views/CustomerStartFromScratch.vue'),
                // },
                // {
                //   path: ':document_id/start-from-excel',
                //   props: true,
                //   component: () => import('./views/CustomerStartFromExcel.vue'),
                // },
                // {
                //   path: ':document_id/start-from-source',
                //   props: true,
                //   component: () => import('./views/CustomerStartFromSource.vue'),
                // },
                // {
                //   path: ':document_id/outreach',
                //   props: true,
                //   component: () => import('./views/CustomerOutreach.vue'),
                // },
                // {
                //   path: ':document_id/record/:record_id',
                //   props: true,
                //   component: () => import('./views/CustomerRecord.vue'),
                // },
                {
                  path: 'group/:record_id',
                  props: true,
                  component: () => import('./views/CustomerGroup.vue'),
                },

                {
                  path: ':document_id-:saved_filter_id',
                  props: true,
                  component: () => import('./views/CustomerTableWithoutLabel.vue'),
                },

                {
                  path: ':document_id',
                  props: true,
                  component: () => import('./views/CustomerTableLayout.vue'),
                  children: [
                    // {
                    //   path: 'start-from-scratch',
                    //   props: true,
                    //   component: () => import('./views/CustomerStartFromScratch.vue'),
                    // },
                    // {
                    //   path: 'start-from-excel',
                    //   props: true,
                    //   component: () => import('./views/CustomerStartFromExcel.vue'),
                    // },
                    {
                      path: 'setting',
                      props: true,
                      component: () => import('./views/CustomerSetting.vue'),
                    },
                    {
                      path: 'outreach',
                      props: true,
                      component: () => import('./views/CustomerOutreach.vue'),
                    },
                    {
                      path: 'outreach2',
                      props: true,
                      component: () => import('./views/CustomerOutreach2.vue'),
                    },
                    {
                      path: 'changelog',
                      props: true,
                      component: () => import('./views/CustomerChangelog.vue'),
                    },
                    {
                      path: 'export',
                      props: true,
                      component: () => import('./views/CustomerExport.vue'),
                    },
                    {
                      path: '',
                      props: true,
                      component: () => import('./views/CustomerTableWithoutLabel.vue'),
                    },
                  ]
                },

                {
                  path: ':document_id-:saved_filter_id/:label',
                  props: true,
                  component: () => import('./views/CustomerTableLayout.vue'),
                  children: [
                    {
                      path: 'start-from-scratch',
                      props: true,
                      component: () => import('./views/CustomerStartFromScratch.vue'),
                    },
                    {
                      path: 'start-from-excel',
                      props: true,
                      component: () => import('./views/CustomerStartFromExcel.vue'),
                    },
                    {
                      path: 'setting',
                      props: true,
                      component: () => import('./views/CustomerSetting.vue'),
                    },
                    {
                      path: 'outreach',
                      props: true,
                      component: () => import('./views/CustomerOutreach.vue'),
                    },
                    {
                      path: 'outreach2',
                      props: true,
                      component: () => import('./views/CustomerOutreach2.vue'),
                    },
                    {
                      path: 'changelog',
                      props: true,
                      component: () => import('./views/CustomerChangelog.vue'),
                    },
                    {
                      path: 'export',
                      props: true,
                      component: () => import('./views/CustomerExport.vue'),
                    },
                    {
                      path: '',
                      props: true,
                      component: () => import('./views/CustomerTable.vue'),
                      children: [
                        {
                          path: ':record_row_id/:record_id',
                          props: true,
                          component: () => import('./views/CustomerRecord.vue'),
                        },
                      ]
                    },
                  ]
                },
                {
                  path: ':document_id/:label',
                  props: true,
                  component: () => import('./views/CustomerTableLayout.vue'),
                  children: [
                    {
                      path: 'start-from-scratch',
                      props: true,
                      component: () => import('./views/CustomerStartFromScratch.vue'),
                    },
                    {
                      path: 'start-from-excel',
                      props: true,
                      component: () => import('./views/CustomerStartFromExcel.vue'),
                    },
                    {
                      path: 'start-from-excel-import',
                      props: true,
                      component: () => import('./views/CustomerStartFromExcelImport.vue'),
                    },
                    {
                      path: 'start-from-db',
                      props: true,
                      component: () => import('./views/CustomerStartFromDB.vue'),
                    },
                    {
                      path: 'setting',
                      props: true,
                      component: () => import('./views/CustomerSetting.vue'),
                    },
                    {
                      path: 'outreach',
                      props: true,
                      component: () => import('./views/CustomerOutreach.vue'),
                    },
                    {
                      path: 'outreach2',
                      props: true,
                      component: () => import('./views/CustomerOutreach2.vue'),
                    },
                    {
                      path: 'changelog',
                      props: true,
                      component: () => import('./views/CustomerChangelog.vue'),
                    },
                    {
                      path: 'export',
                      props: true,
                      component: () => import('./views/CustomerExport.vue'),
                    },
                    {
                      path: 'segment',
                      props: true,
                      component: () => import('./views/CustomerSegment.vue'),
                      children: [
                        {
                          path: ':record_row_id/:record_id',
                          props: true,
                          component: () => import('./views/CustomerRecord.vue'),
                        },
                      ]
                    },
                    {
                      path: '',
                      props: true,
                      component: () => import('./views/CustomerTable.vue'),
                      children: [
                        {
                          path: ':record_row_id',
                          props: true,
                          component: () => import('./views/CustomerRecord.vue'),
                        },
                        {
                          path: ':record_row_id/:record_id',
                          props: true,
                          component: () => import('./views/CustomerRecord.vue'),
                        },
                      ]
                    },
                  ]
                },

                // {
                //   path: ':document_id/:label',
                //   props: true,
                //   component: () => import('./views/CustomerTableLayout.vue'),
                //   children: [
                //     {
                //       path: '',
                //       props: true,
                //       component: () => import('./views/CustomerTable.vue'),
                //       children: [
                //         {
                //           path: ':record_id',
                //           props: true,
                //           component: () => import('./views/CustomerRecord.vue'),
                //         },
                //       ]
                //     },
                //   ]
                // },
              ]
            },
            {
              path: 'message/:message_id/audience',
              props: true,
              component: () => import('./views/MessageRecord.vue'),
            },
            {
              path: 'message/reply',
              props: true,
              component: () => import('./views/MessageReply.vue'),
            },
            {
              path: 'message/:filter_string',
              props: true,
              component: () => import('./views/MessageTable.vue'),
            },
            {
              path: '',
              props: true,
              component: () => import('./views/StatusFlow.vue'),
              children: [
                {
                  path: 'order/:sf_id',
                  props: true,
                  component: () => import('./views/OrderList.vue'),
                  children: [
                    {
                      path: ':order_id',
                      props: true,
                      component: () => import('./views/OrderDetail.vue'),
                    },
                  ],
                },
                {
                  path: 'order',
                  redirect: 'order/all',
                }
              ],
            },
            {
              path: 'manage',
              props: true,
              component: () => import('./views/ManageLayout.vue'),
              children: [
                {
                  path: '/',
                  redirect: 'document',
                },
                {
                  path: 'sms',
                  props: true,
                  component: () => import('./views/ManageSMS.vue'),
                },
                {
                  path: 'email',
                  props: true,
                  component: () => import('./views/ManageEmail.vue'),
                },
                {
                  path: 'kakao',
                  props: true,
                  component: () => import('./views/ManageKakao.vue'),
                },
                {
                  path: 'import',
                  props: true,
                  component: () => import('./views/ManageImport.vue'),
                },
                {
                  path: 'export',
                  props: true,
                  component: () => import('./views/ManageExport.vue'),
                },
                {
                  path: 'status-flow',
                  props: true,
                  component: () => import('./views/ManageStatusFlow.vue'),
                },
                {
                  path: 'document',
                  props: true,
                  component: () => import('./views/ManageDocument.vue'),
                },
                {
                  path: 'canceled-orders',
                  props: true,
                  component: () => import('./views/ManageCanceledOrder.vue'),
                },
                {
                  path: 'message-template',
                  props: true,
                  component: () => import('./views/ManageMessageTemplate.vue'),
                },
                {
                  path: 'menu',
                  props: true,
                  component: () => import('./views/ManageMenu.vue'),
                },
                {
                  path: 'db',
                  props: true,
                  component: () => import('./views/ManageDB.vue'),
                },
              ]
            },
            {
              path: '*',
              name: 'property-page-404',
              component: PropertyPage404,
            },
          ]
        },
        {
          path: 'workspaces',
          name: 'workspace.list',
          props: true,
          component: () => import('./views/contact/WorkspaceList.vue'),
          meta: {
            sessionRequired: true,
            scopeRequired: ['signed'],
          }
        },
        {
          path: ':property_subdomain/accept-invite',
          name: 'workspace.accept_invite',
          props: true,
          component: () => import('./views/contact/WorkspaceAcceptInvite.vue'),
          meta: {
            sessionRequired: true,
            scopeRequired: ['signed'],
          }
        },
        {
          path: ':property_subdomain',
          name: 'layout',
          props: true,
          abstract: true,
          component: () => import('./views/contact/Layout.vue'),
          meta: {
            sessionRequired: true,
            scopeRequired: ['signed'],
          },
          children: [
            {
              name: 'contact.today',
              path: '',
              props: true,
              component: () => import('./views/contact/ContactToday.vue'),
            },
            {
              name: 'contact.view',
              path: 'contacts',
              props: true,
              component: () => import('./views/contact/ContactView.vue'),
               children: [
                {
                  name: 'contact.view.record',
                  path: ':document_record_id',
                  props: true,
                  abstract: true,
                },
              ],
            },
            {
              name: 'calendar.view',
              path: 'calendars',
              props: true,
              component: () => import('./views/contact/CalendarView.vue'),
               children: [
                {
                  name: 'calendar.view.record',
                  path: ':document_record_id',
                  props: true,
                  abstract: true,
                },
              ],
            },
            {
              name: 'pipeline.view',
              path: 'pipelines/:pipeline_name\\-:pipeline_id(\\d+)',
              props: true,
              component: () => import('./views/contact/PipelineView.vue'),
               children: [
                {
                  name: 'pipeline.view.record',
                  path: ':document_record_id',
                  props: true,
                  abstract: true,
                },
              ],
            },
            // {
            //   name: 'document.view.filter',
            //   path: ':document_name\\-:document_id\\-:saved_filter_id(\\d+)',
            //   props: true,
            //   component: () => import('./views/contact/ProjectView.vue'),
            // },
            {
              name: 'document.view',
              path: ':document_name\\-:document_id(\\d+)',
              props: true,
              component: () => import('./views/contact/ProjectView.vue'),
              children: [
                {
                  name: 'document.view.record',
                  path: ':document_record_id',
                  props: true,
                  abstract: true,
                  // component: () => import('./views/contact/ProjectRecordView.vue'),
                },
              ],
            },
          ]
        },
      ],
    },
    {
      path: '*',
      name: 'page-404',
      component: Page404,
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.sessionRequired)) {
    NProgress.start()
    console.log('check signed')
    try {
      const r = await router.app.$store.dispatch('session')
      if (r === true) {
        // segment identify
        if (window.analytics) {
          const session = router.app.$store.state.session
          // window.analytics.identify(session.id, {
          //   email: session.email,
          // }, {
          //   integrations: {
          //     Intercom: {
          //       user_hash: session.intercom_user_hash,
          //     }
          //   }
          // });
          setTimeout(() => {
            console.log(router.app.$store.state.property.id, router.app.$store.state.property.name)
            window.analytics.identify(session.id, {
              email: session.email,
              company: {
                id: router.app.$store.state.property && router.app.$store.state.property.id,
                name: router.app.$store.state.property && router.app.$store.state.property.name,
              }
            }, {
              integrations: {
                Intercom: {
                  user_hash: session.intercom_user_hash,
                }
              }
            });
          }, 300);
        }

        const scopes = flatten(compact(to.matched.map(record => record.meta.scopeRequired)))
        if (scopes.length) {
          console.log('>>>>', router.app.$store.state.session.scope, scopes)
          for (const scope of scopes) {
            if (!router.app.$store.state.session.scope.includes(scope)) {
              if (!router.app.$store.state.session.scope || router.app.$store.state.session.scope.length === 0) {
                return next({
                  name: 'signup.connect',
                })
              }
              router.app.$modal.show('dialog', {
                title: '알림',
                text: '권한이 없습니다.',
              })
              return next(false)
            }
          }
        }
        return next()
      } else {
        router.app.$modal.show('dialog', {
          title: '알림',
          text: '로그인이 필요합니다. (5000)',
        })
        return next({
          path: '/',
        })
      }
    } catch (error) {
      router.app.$modal.show('dialog', {
        title: '알림',
        text: error.message,
      })
      return next({
        path: '/',
      })
    }
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
  setTimeout(() => {
    try {
      const el = document.querySelector('input[autofocus]')
      if (el) {
        el.focus()
      }
    } catch (error) {
      console.log('autofocus failed')
    }
  }, 400);
})

export default router
