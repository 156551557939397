<style lang="sass" scoped>
.container-login
  font-size: 12px
</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.text-center
          span Page Not Found
          strong  404
        h6.text-muted.text-center
        .mb-4

          .text-center
            span.text-muted.mr-1 페이지를 찾을 수 없습니다.
            p(style='position: fixed; bottom: 0px; left: 1rem') Noitaler by Studio INTO

</template>

<script>
import IndexNav from '../components/IndexNav'
import IndexFooter from '../components/IndexFooter'

export default {
  name: 'page404',
  components: {
    IndexNav,
    IndexFooter,
  },
  data() {
    return {
      done: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.done = true
    }, 300);
  }
}
</script>
